import React from 'react'
import './Resellers.css'
import { RESELLERS } from '../data/resellers'
import { removeDuplicates } from '../util/array'

const regions = removeDuplicates(RESELLERS.map((r) => r.region))

export default () => {
  const [region, setRegion] = React.useState(regions[0])

  return (
    <div className="Resellers">
      <div className="grigino-head-line">
        <section className="container">
          <h2 className="titolo">Rivenditori</h2>

          <section id="rivenditori" className="row">
            <div className="select">
              <label htmlFor="regions">Nella regione</label>
              <select
                name="regions"
                value={region}
                onChange={(evt) => setRegion(evt.target.value)}
              >
                {regions.map((r) => (
                  <option key={r}>{r}</option>
                ))}
              </select>
            </div>

            <div className="list">
              {RESELLERS.filter((item) => item.region === region).map(
                (item, index) => {
                  return (
                    <div className="box-riv" key={index}>
                      <h3>{item.name}</h3>
                      {typeof item.address !== 'undefined' ? (
                        <p>
                          <span>indirizzo</span>
                          <br></br>
                          {item.address.street}
                          <br></br>
                          {cityLine(item.address)}
                          <br></br>
                        </p>
                      ) : null}
                      {typeof item.contacts !== 'undefined' ? (
                        <React.Fragment>
                          <p>
                            <span>tel</span>
                            <br></br>
                            <a href={`tel:${item.contacts.phone}`}>
                              {item.contacts.phone}
                            </a>
                          </p>

                          {typeof item.contacts.sito !== 'undefined' ? (
                            <p>
                              <span>sito web</span>
                              <br></br>
                              <a
                                target="_blank"
                                rel="noopener noreferrer"
                                href={item.contacts.sito}
                              >
                                {item.contacts.sito}
                              </a>
                            </p>
                          ) : null}
                        </React.Fragment>
                      ) : null}
                    </div>
                  )
                }
              )}
            </div>
          </section>
        </section>
      </div>

      <hr className="line-verde"></hr>

      <section className="container">
        <section id="squadra" className="row">
          <div className="col-sm-6">
            <h4>Vuoi unirti alla squadra?</h4>
            <p>
              Siamo sempre alla ricerca di forze nuove, nuovi partner che ci
              aiutino ad aggredire un mercato sempre pi&ugrave; competitivo.
              <br></br>
              Se volete entrare a far parte della nostra squadra inviate pure
              una mail all'indirizzo:{' '}
              <a className="highmail" href="mailto:info@infostorenet.it">
                info@infostorenet.it
              </a>
            </p>
          </div>
          <div className="col-sm-offset-1 col-sm-5">
            <h4>I nostri partner</h4>
            <img
              src="https://res.cloudinary.com/asantalu/image/upload/v1583736106/riv-infomat.jpg"
              alt="Partner di Infostore: Infomat"
            />
            <img
              src="https://res.cloudinary.com/asantalu/image/upload/v1583736106/gabtamagnini.jpg"
              alt="Partner di Infostore: CreatorMail Italia"
            />
          </div>
        </section>
      </section>
    </div>
  )
}

const cityLine = ({ cap, city, province }) =>
  [cap, city, province].filter((x) => typeof x !== 'undefined').join(' ')
