import React from 'react';

// import PageHeader from '../components/PageHeader'
// import LazyImage from '../components/LazyImage'
// import Content from '../components/Content.js'
import './About.css';
import News from './News';

export default ({news}) => {
  return (
    <React.Fragment>
      <section id="chi-siamo" className="container">

        <h2 className="titolo">Chi siamo</h2>

        <div className="riga">
          <span id="c-leader-icona" className="b"></span>
          <h4>Leader nell'erogazione di <span className="b-verde">servizi innovativi</span> per retailer italiani</h4>
        </div>
        
        <div className="riga">
          <span id="c-ricerca-icona" className="b"></span>
          <h4><span className="b-verde">Ricerchiamo</span> continuamente innovazione in ogni parte del mondo per renderla disponibile ai nostri clienti</h4>
        </div>

        <div className="riga">
          <span id="c-prodotti-icona" className="b"></span>
          <h4>Leader nella distribuzione di <span className="b-verde">prodotti innovativi</span> per l'automazione del negozio</h4>
        </div>

        <div className="riga">
          <span id="c-rete-icona" className="b"></span>
          <h4>Operiamo con una <span className="b-verde">rete di concessionari</span> su tutto il territorio</h4>
        </div>

        <div className="riga">
          <span id="c-competitivi-icona" className="b"></span>
          <h4>Aiutiamo il retailer a fare negozio in modi innovativi introducendo risparmi significativi ed aiutandoli ad essere pi&uacute; <span className="b-verde">competitivi</span></h4>
        </div>
    </section>

    <News news={news}></News>

      {/* <PageHeader
        title={title}
        subtitle={subtitle}
        backgroundImage={featuredImage}
      />
      <div className='section'>
        <div className='container'>
          <Content source={section1} />
        </div>
      </div>
      <div className='section'>
        <div className='container'>
          <Content source={section2} />
          <p>The image below is a {'<LazyImage />'}</p>
          <LazyImage src={featuredImage} alt='LazyImage' />
        </div>
      </div> */}
    </React.Fragment>
  )
}
