import React from 'react'
// import './Privacy.css'

export default () => {
  return (
    <div className="Privacy">
      <div className="grigino-head-line">
        <section className="container">
          <section className="row head-line">
            <h4>Informativa</h4>
            <p>
              Infostore Servizi si impegna a proteggere la tua privacy e la
              tecnologia di sviluppo fornendoti la più potente e sicura
              esperienza online. Questa Informativa sulla Privacy si applica al
              InfoStore servizi del sito web e gestisce i dati raccolti e il
              loro utilizzo. Usando il sito InfoStore servizi, acconsenti
              all'utilizzo dei dati descritto in questa informativa.{' '}
            </p>
          </section>
        </section>
      </div>

      <section className="container">
        <section className="row">
          <div className="privacy-blocco col-lg-8 col-lg-offset-2 col-md-offset-2 col-md-8">
            <h5>Raccolta dei tuoi dati personali</h5>
            <p>
              InfoStore servizi raccoglie dati personali, come ad esempio il tuo
              indirizzo e-mail, nome, indirizzo di casa o del lavoro o numero di
              telefono. InfoStore servizi raccoglie anche informazioni
              demografiche anonime, non riservate, come il tuo codice ZIP, età,
              genere, preferenze, interessi e favoriti.
              <br></br>
              <br></br>
              Si prega di tenere presente che se si fornisce dati personali o
              dati personali sensibili direttamente tramite servizi InfoStore
              bacheche pubbliche, queste informazioni possono essere raccolte e
              usate da altri.
              <br></br>
              Nota: InfoStore servizi non legge le tue comunicazione online
              private.
              <br></br>
              <br></br>
              InfoStore servizi ti incoraggia a leggere le normative sulla
              privacy dei siti web ai quali ti colleghi tramite link da Servizi
              InfoStore affinché tu possa capire come quei siti web raccolgono,
              usano e condividono le tue informazioni. InfoStore servizi non è
              responsabile per le normative sulla privacy o altri contenuti su
              siti web esterni di servizi InfoStore e la famiglia servizi
              InfoStore di siti web.
            </p>
          </div>

          <div className="privacy-blocco col-lg-8 col-lg-offset-2 col-md-offset-2 col-md-8">
            <h5>Uso dei tuoi dati personali</h5>
            <p>
              InfoStore servizi raccoglie e usa i tuoi dati personali per
              operare sul sito web e fornire i servizi che hai richiesto.{' '}
              <br></br>
              InfoStore inoltre usa i tuoi dati personali per informarti di
              altri prodotti o servizi disponibili da servizi InfoStore ed i
              suoi affiliati. InfoStore può anche contattarti tramite sondaggi
              per condurre una ricerca sulla tua opinione dei servizi attuali o
              di eventuali servizi nuovi che potrebbero essere offerti.
              <br></br>
              <br></br>
              InfoStore servizi non vende, ne affitta la sua lista clienti a
              terzi. Servizi InfoStore può, di tanto in tanto, contattarti per
              conto di soci d'affari esterni per informare di particolari
              offerte che ti potrebbero interessare. In questi casi, i tuoi dati
              personali riservati (e-mail, nome, indirizzo, numero di telefono)
              non vengono trasferite a terzi. Inoltre, servizi InfoStore può
              condividere dati con soci di fiducia per aiutarci ad effettuare
              analisi statistiche, inviarti email o posta ordinaria, fornire
              assistenza clienti, o organizzare consegne. A tutti questi è
              vietato usare i tuoi dati personali salvo per fornire questi
              servizi a servizi InfoStore, e sono tenuti a mantenere la.
              riservatezza delle tue informazioni.<br></br>
              InfoStore servizi non usa ne fornisce dati personali sensibili,
              come informazioni razziali, su fede religiosa, o di carattere
              politico, senza il tuo esplicito consenso.<br></br>
              InfoStore servizi registra i siti web e le pagine visitate dai
              nostri clienti all'interno di servizi InfoStore, al fine di
              determinare quali servizi sono più popolari. Questi dati vengono
              usati per fornire contenuti e pubblicità personalizzata
              all'interno InfoStore servizi a clienti il quale comportamento
              indica che sono interessati ad un particolare argomento.
              <br></br>
              <br></br>
              InfoStore servizi siti web forniranno i tuoi dati personali, senza
              preavviso, solo se necessario per legge o in buona fede che tale
              azione sia necessaria per: (a) conformarsi alle disposizioni di
              legge o conformarsi con le procedure legali InfoStore su servizi o
              il sito; (b) proteggere e difendere i diritti o la proprietà di
              InfoStore e, (c) agire in circostanze urgenti per proteggere la
              sicurezza personale degli utenti dei servizi InfoStore, o del
              pubblico.
            </p>
          </div>

          <div className="privacy-blocco col-lg-8 col-lg-offset-2 col-md-offset-2 col-md-8">
            <h5>Sicurezza dei tuoi dati personali</h5>
            <p>
              InfoStore servizi protegge i tuoi dati personali da accesso, uso o
              divulgazione. InfoStore servizi mantiene i dati personali che
              fornisci su server in un ambiente controllato e sicuro, protetti
              da accesso, uso o divulgazione.<br></br>
              Quando le informazioni personali (come numero di carta di credito)
              vengono trasmessi ad altri siti web, sono protetti tramite l'uso
              di codificazione, come il protocollo Secure Socket Layer (SSL).
            </p>
          </div>

          <div className="privacy-blocco col-lg-8 col-lg-offset-2 col-md-offset-2 col-md-8">
            <h5>Modifiche a questa normativa</h5>
            <p>
              Servizi InfoStore a volte aggiornerà questa Normativa sulla
              Privacy per rispecchiare la reazione dell'azienda e dei clienti.
              InfoStore servizi ti incoraggia a rivedere periodicamente questa
              Normativa per essere informato su come InfoStore servizi protegge
              i tuoi dati.
            </p>
          </div>

          <div className="privacy-blocco col-lg-8 col-lg-offset-2 col-md-offset-2 col-md-8">
            <h5>Informazioni contatto</h5>
            <p>
              InfoStore servizi volentieri i tuoi commenti riguardo a questa
              Normativa sulla Privacy. Se credi che Servizi InfoStore non abbia
              aderito a questa Normativa, ti preghiamo di contattare InfoStore a
              info@infostorenet.it
              <br></br>
              Useremo tentativi commercialmente ragionevoli per determinare e
              rimediare al problema.
            </p>
          </div>
        </section>
      </section>
      {/* <PageHeader
        title={title}
        subtitle={subtitle}
        backgroundImage={featuredImage}
      />

      <div className="section Contact--Section1">
        <div className="container Contact--Section1--Container">
          <div>
            <Content source={body} />

            <div className="Contact--Details">
              {address && (
                <a
                  className="Contact--Details--Item"
                  href={`https://www.google.com.au/maps/search/${encodeURI(
                    address
                  )}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <MapPin /> {address}
                </a>
              )}
              {phone && (
                <a className="Contact--Details--Item" href={`tel:${phone}`}>
                  <Smartphone /> {phone}
                </a>
              )}
              {email && (
                <a className="Contact--Details--Item" href={`mailto:${email}`}>
                  <Mail /> {email}
                </a>
              )}
            </div>
          </div>

          <div>
            <EnquiryFormSimpleAjax name="Simple Form Ajax" />
          </div>
        </div>
      </div> */}
    </div>
  )
}
