import React, { Component, Fragment } from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import Helmet from 'react-helmet'

import ScrollToTop from './components/ScrollToTop'
import Meta from './components/Meta'
import Home from './views/Home'
import About from './views/About'
// import Blog from './views/Blog'
// import SinglePost from './views/SinglePost'
import Contact from './views/Contact'
import Privacy from './views/Privacy'
import Services from './views/Services'
import Products from './views/Products'
import Product from './views/Product'
import NoMatch from './views/NoMatch'
import Nav from './components/Nav'
import Footer from './components/Footer'
// import GithubCorner from './components/GithubCorner'
import ServiceWorkerNotifications from './components/ServiceWorkerNotifications'
import data from './data.json'
import { slugify } from './util/url'
// import { documentHasTerm, getCollectionTerms } from './util/collection'
import Resellers from './views/Resellers'

const RouteWithMeta = ({ component: Component, ...props }) => (
  <Route
    {...props}
    render={routeProps => (
      <Fragment>
        <Meta {...props} />
        <Component {...routeProps} {...props} />
      </Fragment>
    )}
  />
)

class App extends Component {
  state = {
    data,
  }

  getDocument = (collection, name) =>
    this.state.data[collection] &&
    this.state.data[collection].filter(page => page.name === name)[0]

  getDocuments = collection => this.state.data[collection] || []

  render() {
    const globalSettings = this.getDocument('settings', 'global')
    const {
      siteTitle,
      siteUrl,
      siteDescription,
      socialMediaCard,
      headerScripts,
    } = globalSettings

    const products = this.getDocuments('products').filter(
      post => post.status !== 'Draft'
    )

    const news = this.getDocuments('news')
    // const posts = this.getDocuments('posts').filter(
    //   post => post.status !== 'Draft'
    // )

    const featured = products.find(p => p.status.includes('Featured'))
    // const categoriesFromPosts = getCollectionTerms(posts, 'categories')
    // const categoriesFromProducts = getCollectionTerms(products, 'productCategories')

    // const productCategories = this.getDocuments('productCategories').map(
    //   p => p.title
    // )
    // const postCategories = this.getDocuments('postCategories').filter(
    //   category => categoriesFromPosts.indexOf(category.name.toLowerCase()) >= 0
    // )

    return (
      <Router>
        <div className="React-Wrap">
          <ScrollToTop />
          <ServiceWorkerNotifications reloadOnUpdate />
          {/* <GithubCorner url='https://github.com/Jinksi/netlify-cms-react-starter' /> */}
          <Helmet
            defaultTitle={siteTitle}
            titleTemplate={`${siteTitle} | %s`}
          />
          <Meta
            headerScripts={headerScripts}
            absoluteImageUrl={
              socialMediaCard &&
              socialMediaCard.image &&
              siteUrl + socialMediaCard.image
            }
            twitterCreatorAccount={
              socialMediaCard && socialMediaCard.twitterCreatorAccount
            }
            twitterSiteAccount={
              socialMediaCard && socialMediaCard.twitterSiteAccount
            }
          />

          <Nav />

          <Switch>
            <RouteWithMeta
              path="/"
              exact
              component={Home}
              news={news}
              products={products
                .filter(p => !p.status.includes('Featured'))
                .slice(0, 3)}
              featured={featured}
              description={siteDescription}
              fields={this.getDocument('pages', 'home')}
            />
            <RouteWithMeta
              path="/about"
              exact
              news={news}
              component={About}
              fields={this.getDocument('pages', 'about')}
            />
            <RouteWithMeta
              path="/contact"
              exact
              component={Contact}
              fields={this.getDocument('pages', 'contact')}
              siteTitle={siteTitle}
            />
            <RouteWithMeta
              path="/products"
              component={Products}
              categories={this.getDocuments('productCategories')}
              // fields={this.getDocument('pages', 'contact')}
              products={products}
              siteTitle={siteTitle}
            />

            {/* <RouteWithMeta
              path="/product"
              component={Product}
              // fields={this.getDocument('pages', 'contact')}
              products={products}
              siteTitle={siteTitle}
            /> */}

            <RouteWithMeta
              path="/services"
              exact
              component={Services}
              siteTitle={siteTitle}
            />
            {/* <RouteWithMeta
              path='/services/shop/'            
              component={ServiceShop}
              siteTitle={siteTitle}
            />
             <RouteWithMeta
              path='/services/sales/'            
              component={Services}
              siteTitle={siteTitle}
            />
             <RouteWithMeta
              path='/services/moneymanagement/'            
              component={Services}
              siteTitle={siteTitle}
            />
             <RouteWithMeta
              path='/services/relations/'            
              component={Services}
              siteTitle={siteTitle}
            /> */}
            <RouteWithMeta
              path="/privacy"
              exact
              component={Privacy}
              siteTitle={siteTitle}
            />
            <RouteWithMeta
              path="/resellers"
              exact
              component={Resellers}
              siteTitle={siteTitle}
            />
            {/* <RouteWithMeta
              path='/blog/'
              exact
              component={Blog}
              fields={this.getDocument('pages', 'blog')}
              posts={posts}
              postCategories={postCategories}
            /> */}

            {products.map((product, index) => {
              const path = slugify(`/product/${product.title}`)
              return (
                <RouteWithMeta
                  key={path}
                  path={path}
                  exact
                  component={Product}
                  product={product}
                  // fields={post}
                  // nextPostURL={nextPost && slugify(`/blog/${nextPost.title}/`)}
                  // prevPostURL={prevPost && slugify(`/blog/${prevPost.title}/`)}
                />
              )
            })}

            {/* {posts.map((post, index) => {
              const path = slugify(`/blog/${post.title}`)
              const nextPost = posts[index - 1]
              const prevPost = posts[index + 1]
              return (
                <RouteWithMeta
                  key={path}
                  path={path}
                  exact
                  component={SinglePost}
                  fields={post}
                  nextPostURL={nextPost && slugify(`/blog/${nextPost.title}/`)}
                  prevPostURL={prevPost && slugify(`/blog/${prevPost.title}/`)}
                />
              )
            })}

            {postCategories.map(postCategory => {
              const slug = slugify(postCategory.title)
              const path = slugify(`/blog/category/${slug}`)
              const categoryPosts = posts.filter(post =>
                documentHasTerm(post, 'categories', slug)
              )
              return (
                <RouteWithMeta
                  key={path}
                  path={path}
                  exact
                  component={Blog}
                  fields={this.getDocument('pages', 'blog')}
                  posts={categoryPosts}
                  postCategories={postCategories}
                />
              )
            })} */}

            <Route render={() => <NoMatch siteUrl={siteUrl} />} />
          </Switch>
          <Footer />
        </div>
      </Router>
    )
  }
}

export default App
