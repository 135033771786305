/* eslint-disable no-unused-vars */
import React from 'react'
import Content from '../components/Content.js'
// import './Resellers.css'

export default ({product}) => {

  const {
    title,
    brand,
    postFeaturedImage,
    excerpt,
    categories,
    content,
    status,
  } = product

  return (
    <div className="Product">
     <section className="container">
        <section className="row" id="scheda">
            {/* <div className="col-lg-12" id="percorso">
                <a onClick={() => this.goBack()}><span className="glyphicon glyphicon-chevron-left"></span>torna indietro</a>
            </div> */}
            
            <div className="col-lg-12" id="scheda-name">
                <h3>{title}</h3>
                <h4>{brand}</h4>
            </div>
            
            <div className="col-lg-9" id="scheda-desc">
            <Content source={content} />
                {/* <p>{content}</p> */}
                {/* <div>
                    <p id="scheda-colli"><span>5</span> in magazzino</p>
                </div>
                <div>
                    <a href="" className="scheda-btn scheda-btn-grigino">Maggiori<br></br>informazioni</a>
                    <a href="" className="scheda-btn scheda-btn-verdino"><p>scarica<br></br>brochure</p> <img src="https://res.cloudinary.com/asantalu/image/upload/v1583736106/i-brochure.png" width="23" height="26" alt="scarica brochure"/></a>
                </div> */}
            </div>
            
            <div className="col-lg-3" id="scheda-img">
                <img src={postFeaturedImage} width="305" height="290" alt=""/>
                {/* <div id="scheda-thumbnail">
                    <img src="https://res.cloudinary.com/asantalu/image/upload/v1583736106/kt.jpg" width="54" height="50" alt=""/>
                    <img src="https://res.cloudinary.com/asantalu/image/upload/v1583736106/kt.jpg" width="54" height="50" alt=""/>
                    <img src="https://res.cloudinary.com/asantalu/image/upload/v1583736106/kt.jpg" width="54" height="50" alt=""/>
                </div> */}
            </div>
            
            {/* <div className="col-lg-3" id="scheda-form">
                <form action="">
                    <div>
                        <label for="">Modello</label>
                        <select name="modello" size="1" id="">
                            <option value="valore">valore</option>
                            <option value="valore">valore</option>
                            <option value="valore">valore</option>
                        </select>
                    </div>
                    <div>
                        <label for="">Colore</label>
                        <select name="colore" size="1" id="">
                            <option value="rosso">rosso</option>
                            <option value="bianco">bianco</option>
                            <option value="verde">verde</option>
                        </select>
                    </div>
                    <div>
                        <label for="">Quantit&agrave;</label>
                        <input type="number" name="quantita" value="1" min="1" max="20" step="1"/>
                    </div>
                </form>
                <a className="bot-prod" href="">Aggiungi alla lista</a>
            </div> */}
        </section>
    </section>
    </div>
  )
}
