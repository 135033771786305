import React from 'react'
// import { NavLink } from 'react-router-dom'

// import './Products.css'

export default () => {
  return (
    <div className="Services">
      
      <div className="grigino-head-line">
    <section className="container">
        <section className="row head-line">
            <h4>I servizi che offriamo</h4>
            <p>Offriamo servizi ai nostri rivenditori, e di conseguenza ai nostri clienti, per definire, sviluppare e implementare progetti complessi  che richiedano figure di Project Management, Retail Business Consulting, Store Consulting, Business Intelligence Consulting, capaci di riunire varie competenze al servizio di Retailer italiani ed esteri, fino alla realizzazione di progetti chiavi in mano.</p>
        </section>
    </section>
</div>

<section className="container">
    <section className="row" id="ser-pag">
        <div className="col-sm-5">
            <h3 id="ser-gestione">Gestione del negozio</h3>
        </div>
        <div className="col-sm-7 ser-lista">
            <h4>Recupero di efficienza e produttività</h4>
            <ul>
            <li>Profilazione efficienza barriere di cassa</li>
            <li>Studio predittivo di necessità di personale alle casse</li>
            <li>Analisi del cash management e ottimizzazione del tempo nella chiusura contabile e gestione del denaro</li>
            </ul>
            <h4>Gestione e sviluppo progetto per la vendita di servizi alle casse</h4>
            <ul>
            <li>Ricariche telefoniche</li>
            <li>Gestioni buoni</li>
            <li>Pagamento utenze</li>
            <li>Gestione buoni sconto e carte fedeltà</li>
            </ul>
        </div>
    </section>
</section>

<hr className="line-verde"></hr>

<section className="container">
    <section className="row" id="ser-pag">
        <div className="col-sm-7 ser-lista">
        <h4>Vendita ricariche o Gift Cards, gestione buoni sconto o buoni pasto, gestione delle utenze</h4>
        <ul>
        <li>Chioschi informativi</li>
        <li>Barriera cassa</li>
        </ul>
        </div>
        <div className="col-sm-5">
        <h3 id="ser-vendita">Vendita di prodotti e servizi</h3>
        </div>
    </section>
</section>

<hr className="line-verde"></hr>

<section className="container">
    <section className="row" id="ser-pag">
        <div className="col-sm-5">
        <h3 id="ser-tesoreria">Tesoreria</h3>
        </div>
        <div className="col-sm-7 ser-lista">
        <h4>Ottimizzazione rapporto con gestori di pagamento</h4>
        <ul>
        <li>Indirizzare traffico su Acquirer con condizioni migliori</li>
        <li>Gestione firma in formato digitale</li>
        <li>Pagamenti e-commerce</li>
        <li>Servizi per la gestione dei contratti con Acquirer e Processor dei contenuti gestiti da UPB</li>
        </ul>
        <h4>Gestione del cash e relazione con le banche</h4>
        <ul>
        <li>Ottimizzare gestione del denaro per taglio</li>
        <li>Ottimizzare gestione del fondo cassa</li>
        <li>Ottimizzare tempi di apertura e chiusura cassa e conciliazione con sistemi informativi</li>
        <li>Ottimizzare presa e resa del denaro con le banche</li>
        </ul>
        </div>
    </section>
</section>
    </div>
  )
}
