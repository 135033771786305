export const RESELLERS = [
  {
    region: 'Lombardia',
    name: 'Supertronic',
    address: {
      street: 'Via S. Giovanni Battista De La Salle 4',
      cap: '20132',
      city: 'Milano',
    },
    contacts: {
      phone: '02 89050392',
    },
  },

  {
    region: 'Lombardia',
    name: 'Varc srl',
    address: {
      street: 'Via Giuseppe Meda 36',
      city: 'Milano',
    },
    contacts: {
      phone: '02 89050392',
    },
  },

  {
    region: 'Lombardia',
    name: 'Retex spa',
    address: {
      street: 'Centro direzionale Milanofiori, Strada 4, Ingresso A5',
      cap: '20090',
      city: 'Assago',
      province: 'MI',
    },
    contacts: {
      phone: '02 89050392',
    },
  },

  {
    region: 'Umbria',
    name: 'Leonello lunghi srl',
    address: {
      street: 'viale dei Pini',
      cap: '06081',
      city: 'Assisi',
      province: 'Perugia',
    },
    contacts: {
      phone: '075 8098200'
    }
  },

  {
    region: 'Marche',
    name: 'Gioacchini',
    address: {
      street: 'Via dell\'artigianato, 19',
      cap: '60127',
      city: 'Ancona',
      province: 'AN',
    },
    contacts: {
      phone: '071 2806737',
      sito: 'https://www.gioacchinisrl.com/'
    },
  },

  {
    region: 'Puglia',
    name: 'Professional service srl',
    address: {
      street: 'via Oslavia, 43',
      cap: '73100',
      city: 'Lecce',
    },
    contacts: {
      phone: '0832 231105',
    },
  },


  {
    region: 'Calabria',
    name: 'Bruzia Informatica S.A.S. Di Filice Paola &amp; C.',
    address: {
      street: 'Contrada Cutura',
      cap: '87036',
      city: 'Rende',
      province: 'Cosenza',
    },
    contacts: {
      phone: '098 4483279',
    },
  },

  {
    region: 'Calabria',
    name: 'Grillo telematico',
    address: {
      street: 'Via Rocco Chinnici',
      cap: '87100',
      city: 'Cosenza',
      province: 'Cosenza'
    },
    contacts: {
      phone: '0984 34595',
      sito: 'www.grillotelematico.it'
    }
  },

  {
    region: 'Calabria',
    name: 'Elpi system di rocca filippo giuseppe',
    contacts: {
      phone: '0968 438156',
    },
  },

  {
    region: 'Liguria',
    name: 'Gam Office Service srl',
    contacts: {
      phone: '010 8938481',
    },
  },

  {
    region: 'Liguria',
    name: 'R & B',
    address: {
      street: 'via Vecchia Piemonte, 64',
      cap: '18100',
      city: 'Imperia',
      province: 'IM',
    },
    contacts: {
      phone: '0183 766168',
    },
  },

  {
    region: 'Veneto',
    name: 'Berto paolo',
    address: {
      street: 'via Igna, 21',
      cap: '36030',
      city: 'Zugliano',
      province: 'VI',
    },
    contacts: {
      phone: '044 5360162',
    },
  },

  {
    region: 'Veneto',
    name: 'Serenissima informatica spa',
    address: {
      street: 'via della Croce Rossa, 5',
      cap: '35129',
      city: 'Padova',
    },
    contacts: {
      phone: '049 829 1111',
      sito: 'https://www.serinf.it/',
    },
  },

  {
    region: 'Lazio',
    name: 'QSPOS',
    address: {
      street: 'Via Cornelia, 498',
      building: 'Pal. G Tecnocenter Talenti'
    },
    contacts: {
      phone: '06 50797743',
      sito: 'qsnetsrl.com'
    }
  },

  {
    region: 'Lazio',
    name: 'it-avantec',
    address: {
      street: 'via Madonna di Ponza',
      city: 'SNC, FORMIA',
    },
    contacts: {
      phone: '071 9985827',
    },
  },

  {
    region: 'Campania',
    name: 'DF Sistemi',
    address: {
      street: 'Via Nazionale Appia, 255',
      cap: '81028',
      city: 'S. Maria a Vico',
      province: 'CE',
    },
    contacts: {
      phone: '0823759226',
      sito: 'http://www.dfsistemi.it/dfsistemi.it',
    },
  },

  {
    region: 'Campania',
    name: 'DF Sistemi',
    address: {
      street: 'Via Nazionale Appia, 255',
      cap: '81028',
      city: 'S. Maria a Vico',
      province: 'CE'
    },
    contacts: {
      phone: '0823759226',
      sito: 'http://www.dfsistemi.it/dfsistemi.it'
    }
  },

  {
    region: 'Campania',
    name: 'computers service srl',
    address: {
      street: 'via Larga, area PIP, lotto 1.13',
      cap: '81038',
      city: 'Trentola Ducenta',
      province: 'CE',
    },
    contacts: {
      phone: '081 8120044',
    },
  },

  {
    region: 'Campania',
    name: 'easy media solutions srl',
    address: {
      street: 'via dei Calzolai, 3',
      cap: '80144',
      city: 'Napoli',
    },
    contacts: {
      phone: '081 8048631',
    },
  },

  {
    region: 'Campania',
    name: 'Touch $ Print Sas Di Dario Enrico Ed Acanfora Salvatore & C.',
    address: {
      street: 'via Giacinto Gigante, 154',
      city: 'Napoli',
    },
    contacts: {
      phone: '081 2292123',
    },
  },

  {
    region: 'Emilia Romagna',
    name: 'gab tamagnini',
    address: {
      street: 'via B. Disraeli, 17',
      cap: '42124',
      city: "Reggio nell'Emilia",
      province: 'RE',
    },
    contacts: {
      phone: '0522 383222',
    },
  },

  {
    region: 'Emilia Romagna',
    name: 'mercurio sistemi srl',
    address: {
      street: 'strada Provinciale Nord, 213',
      cap: '42017',
      city: "Novellara Reggio nell'Emilia",
    },
    contacts: {
      phone: '+39 0522 653690',
      sito: 'https://www.mercuriosistemi.it/',
    },
  },

  {
    region: 'Toscana',
    name: 'ds distribution service srl',
    address: {
      street: 'via del Feo, 1',
      city: 'Pisa',
    },
    contacts: {
      phone: '050 809801',
    },
  },

  {
    region: 'Toscana',
    name: 'auriga',
    address: {
      street: 'via Ippolito Rosellini, 10',
      cap: '50127',
      city: 'Firenze',
    },
    contacts: {
      phone: '055 4378361',
      sito: 'http://www.aurigasrl.eu/',
    },
  },

  {
    region: 'Toscana',
    name: 'erreuno',
    address: {
      street: 'via Leonardo da Vinci, 11',
      cap: '53048',
      city: 'Siralunga',
      province: 'SI',
    },
    contacts: {
      phone: '0577 679846',
    },
  },

  {
    region: 'Sicilia',
    name: 'v.d.f. system pos',
    address: {
      street: 'via Macello, 32',
      cap: '95123',
      city: 'Catania',
      province: 'CT',
    },
    contacts: {
      phone: '095 7441347',
    },
  },

  {
    region: 'Sicilia',
    name: 'Sferas Snc Di Assenzo Giuseppe & C.',
    address: {
      street: 'Contrada Durruelli',
      cap: '92014',
      city: 'Porto Empedocle',
      province: 'AG',
    },
    contacts: {
      phone: '0922 601308',
    },
  },

  {
    region: 'Sicilia',
    name: 'vitamia srl',
    address: {
      street: 'via della Resurrezione, 11',
      cap: '90146',
      city: 'Palermo',
    },
    contacts: {
      phone: ' 091 5072543',
    },
  },

  {
    region: 'Sardegna',
    name: 'data system srl',
    address: {
      street: 'via Santa Gilla, 51',
      cap: '09122',
      city: 'Cagliari',
    },
    contacts: {
      phone: '070 288081',
      sito: 'https://datasystempos.it/',
    },
  },

  {
    region: 'Sardegna',
    name: 'Adema srl',
    address: {
      street: 'via Capotesta, 11',
      cap: '07026',
      city: 'Olbia',
      province: 'OT',
    },
    contacts: {
      phone: '+39 0789 25176',
    },
  },
]
