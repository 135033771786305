import React from 'react'

// import PageHeader from '../components/PageHeader'
// import LazyImage from '../components/LazyImage'
// import Content from '../components/Content.js'
// import './About.css'

export default ({news}) => {
  return (
    <div id="news" >
        <section className="container">
            <div className="row">
                <div className="last-news col-lg-12 col-md-12">
                    <h6 className="tit-verde">Retail News</h6>
                    <div className="row">
                    {news.map((n,idx) => {
                        // eslint-disable-next-line no-unused-vars
                        const {title, date, link, excerpt} = n
                        return(<article className="col-lg-4 col-md-4 blocco-news" key={idx}>
                            <p className="tit-news">{title}</p>
                            <p>{excerpt}</p>
                            {link && (<a href={link}>Vai alla notizia <span className="glyphicon glyphicon-arrow-right"></span></a>)}
                        </article>)
                    })}
                    </div>
                </div>
            </div>
        </section>
    </div>
  )
}
