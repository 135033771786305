import React from 'react';
import { Link, NavLink } from 'react-router-dom';

import { slugify } from '../util/url';
import News from './News';

import './Home.css';

export default ({ featured, products, news }) => {
  let featuredElement = <></>
  if (featured) {
    const {
      title: ftitle,
      postFeaturedImage,
      excerpt,
    } = featured
    const path = slugify(`/product/${ftitle}`)
    featuredElement = (
      <div className="col-lg-6 col-md-12 promozione">
        <div className="posiflex">
          <h6>Novità {ftitle}</h6>
          <p>{excerpt}</p>
          <img src={postFeaturedImage} alt={ftitle} width="436" height="288" />
          <NavLink to={path} className="btn-grigino">
            Scopri
          </NavLink>
        </div>
      </div>
    )
  }

  return (
    <main className="Home">
      <div className="verde2">
        <section className="container">
          <div className="row">
            <div id="frasi">
              <h2 id="badge"><span>Offriamo un servizio a 5 stelle</span></h2>
              <div className="frase">
                <span id="b-leader" className="b"></span>
                <h4>
                  Leader nell'erogazione di <strong className="frase-nera">servizi innovativi</strong> per retailer italiani
                </h4>
              </div>
              <div className="frase">
                <span id="b-rete" className="b"></span>
                <h4>
                  Operiamo con una <strong className="frase-nera">rete di concessionari</strong> su tutto il territorio
                </h4>
              </div>
              <div className="frase">
                <span id="b-prodotti" className="b"></span>
                <h4>
                  Leader nella distribuzione di <strong className="frase-nera">prodotti innovativi</strong> per l'automazione del negozio
                </h4>
              </div>
              <div className="frase">
                <span id="b-ricerca" className="b"></span>
                <h4>
                  <strong className="frase-nera">Ricerchiamo</strong> continuamente innovazione in ogni parte del mondo per renderla disponibile ai nostri clienti
                </h4>
              </div>
              <div className="frase">
                <span id="b-competitivi" className="b"></span>
                <h4>
                  Aiutiamo il retailer a fare negozio in modi innovativi introducendo risparmi significativi ed aiutandoli ad essere pi&uacute; <strong className="frase-nera">competitivi</strong>
                </h4>
              </div>
            </div>
          </div>
        </section>
      </div>

      <section className="container home_servizi">
          <div id="servizi" className="titolone"></div>

          <div className="riga_servizio">
            <span id="s-gestione" className="s-icona"></span>

            <div className="s-gestione">
              <h5>Gestione del negozio</h5>
              <div className="blocco">
                <p>Recupero di efficienza e produttivit&agrave;</p>
                <ul>
                  <li>Automazione barriera cassa</li>
                  <li>
                    Studio predittivo necessit&agrave; personale alle casse
                  </li>
                  <li>altro..</li>
                </ul>

                <Link className="btn-servizio" to="/services/">
                  Scopri di pi&ugrave;{' '}
                  <span className="glyphicon glyphicon-chevron-right"></span>
                </Link>
              </div>
            </div>
          </div>

          <div className="riga_servizio">
            <span id="s-vendita" className="s-icona"></span>
            <div className="s-tesoro">
              <h5>Tesoreria</h5>
              <div className="blocco">
                <p>Ottimizzazione rapporto con gestori di pagamento</p>
                <p>Gestione del cash e relazione con le banche</p>
                <Link className="btn-servizio" to="/services/">
                  Scopri di pi&ugrave;{' '}
                  <span className="glyphicon glyphicon-chevron-right"></span>
                </Link>
              </div>
            </div>
          </div>

          <div className="riga_servizio">
            <span id="s-tesoro" className="s-icona"></span>
            <div className="s-vendita">
              <h5>Vendita di prodotti e servizi</h5>
              <div className="blocco">
                <p>
                  Vendita ricariche o Gift Cards, gestione buoni sconto o buoni
                  pasto, gestione delle utenze
                </p>
                <ul>
                  <li>Chioschi informativi</li>
                  <li>altro..</li>
                </ul>
                <Link className="btn-servizio" to="/services/">
                  Scopri di pi&ugrave;{' '}
                  <span className="glyphicon glyphicon-chevron-right"></span>
                </Link>
              </div>
            </div>
          </div>

          <div className="riga_servizio">
            <span id="s-customer" className="s-icona"></span>
            <div className="s-customer">
              <h5>Customer relationship</h5>
              <div className="blocco">
                <p>Riqualificazione figure professionali</p>
                <p>Gestione buoni sconto e carte fedeltà</p>
                <ul>
                  <li>Smartphone Apps</li>
                  <li>altro..</li>
                </ul>
                <Link className="btn-servizio" to="/services/">
                  Scopri di pi&ugrave;{' '}
                  <span className="glyphicon glyphicon-chevron-right"></span>
                </Link>
              </div>
            </div>
          </div>
      </section>

      <div className="grigino home_prodotti">
        <div id="prodotti" className="titolone"></div>

        <section className="container">
          <section className="row">
            <div className={featured ? "col-lg-6 col-md-12 prodotti" : "col-lg-12 col-md-12 prodotti"}>
              {products.map((product, index) => {
                const {
                  title,
                  brand,
                  postFeaturedImage,
                  excerpt
                } = product
                const pathLoc = slugify(`/product/${title}`)
                return (
                  <div className="box-prodotto" key={index}>
                    <div className="box-prodotto-dentro">
                      <NavLink to={pathLoc}>
                        <img src={postFeaturedImage} alt="" />
                      </NavLink>
                      <div>
                        <NavLink className="prod-nome" to={pathLoc}>
                          {title}
                        </NavLink>
                        <p className="prod-marca">{brand}</p>
                        <p className="prod-def">{excerpt}</p>
                      </div>
                    </div>
                  </div>
                )
              })}

              <div className="btn-centro">
                <Link className="btn-verdino" to="/products/">
                  Guarda tutti i prodotti
                </Link>
              </div>
            </div>
            {featuredElement}
            {/* <div className="col-lg-6 col-md-12 promozione">
              <div className="posiflex">
                <h6>Novità {ftitle}</h6>
                <p>{excerpt}</p>
                <img
                  src={postFeaturedImage}
                  alt={ftitle}
                  width="436"
                  height="288"
                />
                <a href={path} className="btn-grigino">
                  Scopri
                </a>
              </div>
            </div> */}
          </section>
        </section>
      </div>

      <News news={news}></News>

      {/* <PageHeader
        large
        title={title}
        subtitle={subtitle}
        backgroundImage={featuredImage}
      />

      <div className='section'>
        <div className='container'>
          <Content source={body} />
        </div>
      </div> */}
    </main>
  )
}
