import React from 'react'
import { Link } from 'react-router-dom'

import './Logo.css'

export default ({ inverted }) => (
  <Link to="/" >
            <img
              src="https://res.cloudinary.com/asantalu/image/upload/v1583736106/logo-head.png"
              alt="Infostore servizi"
              width="171"
              height="58"
            />
          </Link>
)
