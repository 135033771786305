import React from 'react'
// import { MapPin, Smartphone, Mail } from 'react-feather'

// import EnquiryFormSimpleAjax from '../components/EnquiryFormSimpleAjax'
// import Content from '../components/Content'
import './Contact.css'

export default () => {
  return (
    <React.Fragment>
      <h2 className="titolo">Contatti</h2>

        <div id="contacts" className="container">
          <div className="indirizzo">
            <h5>indirizzo</h5>
            <a target="_blank" rel="noopener noreferrer" href="https://goo.gl/maps/aeRQy8pmsRbSY7T59">Via Desenzano 6, 20146 MI</a>
            <h5>e-mail</h5>
            <a href="mail:info@infostorenet.it">info@infostorenet.it</a>
            <h5>tel</h5>
            <a href="tel:0289073955">02 89073955</a>
            <h5>fax</h5>
            <a href="tel:0289073967">02 89073967</a>
          </div>


          <div className="mail">
            <div>
            <h5>
              Claudio Benvenuti
              <span className="role">Presidente</span>
            </h5>
            <p>
            <a href="mail:claudio.benvenuti@infostorenet.it">claudio.benvenuti@infostorenet.it</a>
            </p>
            </div>
            <div>
            <h5>
              Alessandro Santalucia
              <span className="role">Amministratore Delegato</span>
            </h5>
            <p>
              <a href="mail:alessandro.santalucia@infostorenet.it">
                alessandro.santalucia@infostorenet.it
              </a>
              <a href="tel:+39348-8819832">
                +39 348-8819832
              </a>
            </p>
            </div>
            <div>
            <h5>
              Massimo Lonati
              <span className="role">Amministrazione</span>
            </h5>
            <p>
            <a href="mail:massimo.lonati@infostorenet.it">massimo.lonati@infostorenet.it</a>
            </p>
            </div>
            <div>
            <h5>
              Barbara Scotece
              <span className="role">Supporto Commerciale</span>
            </h5>
            <p>
            <a href="mail:barbara.scotece@infostorenet.it">barbara.scotece@infostorenet.it</a>
            </p>
            </div>
            <div>
            <h5>
              Paolo Russo
              <span className="role">
                Dir. Commerciale, soluzioni negozio
              </span>
            </h5>
            <p>
              <a href="mail:paolo.russo@infostorenet.it">
              paolo.russo@infostorenet.it
              </a>
              <a href="tel:+393930387544">
              +39 3930387544
              </a>
            </p>
            </div>
            <div>
            <h5>
              Laura Santalucia
              <span className="role">Web and Graphic designer</span>
            </h5>
            <p>
            <a href="mail:laura.santalucia@infostorenet.it">laura.santalucia@infostorenet.it</a>
            </p>
            </div>
            <div>
            <h5>
              Luca Santalucia <span className="role">Web Master</span>
            </h5>
            <p>
            <a href="mail:luca.santalucia@infostorenet.it">luca.santalucia@infostorenet.it</a>
            </p>
            </div>
          </div>

        </div>
    </React.Fragment>
    /* <PageHeader
        title={title}
        subtitle={subtitle}
        backgroundImage={featuredImage}
      />

      <div className="section Contact--Section1">
        <div className="container Contact--Section1--Container">
          <div>
            <Content source={body} />

            <div className="Contact--Details">
              {address && (
                <a
                  className="Contact--Details--Item"
                  href={`https://www.google.com.au/maps/search/${encodeURI(
                    address
                  )}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <MapPin /> {address}
                </a>
              )}
              {phone && (
                <a className="Contact--Details--Item" href={`tel:${phone}`}>
                  <Smartphone /> {phone}
                </a>
              )}
              {email && (
                <a className="Contact--Details--Item" href={`mailto:${email}`}>
                  <Mail /> {email}
                </a>
              )}
            </div>
          </div>

          <div>
            <EnquiryFormSimpleAjax name="Simple Form Ajax" />
          </div>
        </div>
      </div> */
  )
}
